<template>
  <!-- 开票申请审批 -->
  <div>
    <div class="title"> <i class="el-icon-arrow-left icon" @click="$router.go(-1)"></i>申请开票</div>
    <el-card class="main"> 
    <div style="margin-top: 10px;">
        <p class="text1 left1">本次申请发票</p>
        <div class="heng"></div>
        <el-table
        :header-cell-style="{background:'#9e99b9',color:'#ffff'}"
        :data="dataList"
        style="width: 80%;margin:30px auto">
        <el-table-column
            prop="taskName"
            header-align="center"
            align="center"
            label="任务名称"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            prop="batchNo"
            header-align="center"
            align="center"
            label="订单号(批次号)"
            show-overflow-tooltip
        ></el-table-column>
        <el-table-column
            prop="amount"
            header-align="center"
            align="center"
            label="金额"
            show-overflow-tooltip
        ></el-table-column>
        </el-table>
        <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="currPage"
        :page-sizes="[3,10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalCount"
        class="text_center"
        style="text-align:right;margin-right:160px"
        layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
    </div>
    <div class="ben">
      <p class="text1 left1">发票抬头</p>
      <div class="heng"></div>
      <el-form label-width="140px" class="left1" :model="dataForm" style="margin-top:20px">
        <el-form-item label="企业名称">
          {{dataForm.companyName}}
        </el-form-item>
        <el-form-item label="统一社会信用代码">
           {{dataForm.companyTaxNum}}
        </el-form-item>
        <el-form-item label="对公账户开户行">
            {{dataForm.bankName}}
        </el-form-item>
        <el-form-item label="对公账户号">
           {{dataForm.bankNo}}
        </el-form-item>
        <el-form-item label="公司地址">
            {{dataForm.companyAddress}}
        </el-form-item>
      </el-form>
    </div>
    <div class="ben">
      <p class="text1 left1">开票类目</p>
      <div class="heng"></div>

    </div>
    <div class="ben">
      <p class="text1 left1">请选择地址</p>
      <div class="heng"></div>
        <div class="boxtop">
       <el-carousel type="card" arrow="always" :loop="false" :initial-index="1"  ref="carousel" 
                    indicator-position="none" :autoplay="false" >
          <el-carousel-item  v-for="(item,index) in addressArr" :key="item.index"  @click.native="inter()">
            <el-card class="box-card" :class="{active:index== activeIndex }" >
                      <div slot="header" class="clearfix">
                        <span style="font-size:16px;float: left">收件信息</span>
                     </div>
                      <div>
                      <el-form ref="addressArr" :model="addressArrobj" label-width="100px" class="form itemcalss" >
                          <el-form-item label="收件人姓名">
                            {{item.userName}}
                           </el-form-item>
                          <el-form-item label="联系电话">
                            {{item.userMobile}}
                          </el-form-item>
                            <el-form-item label="邮编">
                              {{item.postCode}}
                         </el-form-item>
                            <el-form-item label="收件人地址">
                              {{item.userAddress}}
                          </el-form-item>
                      </el-form>
                    </div>
                  </el-card>
         </el-carousel-item>
      </el-carousel>  
       </div>
       
    </div>
    <div class="ben">
      <p class="text1 left1">请选择邮寄方式</p>
      <div class="heng"></div>
       <div style="margin-left:160px;margin-top:40px">
              <el-radio v-model="radio" label="502010">月寄</el-radio>
              <el-radio v-model="radio" label="502020">邮寄到付</el-radio>
              <div v-if="radio==502010" style="margin-top:14px">月寄是截止到每月最后一天统一邮寄，快递默认顺丰，无需承担费用。</div>
              <div v-else style="margin-top:14px">邮寄到付是每结算成功一批次，寄一次发票.工作人员收到发票申请后，会在一个工作日内安排寄出，邮寄到付产生的快递费需要自行承担。</div>
      </div>
    </div>
    <div class="ben">
      <p class="text1 left1">备注</p>
      <div class="heng"></div>
        <el-input style="margin-top:30px;width:80%" class="left1" type="textarea" v-model="desc"></el-input>
     <div style="text-align:center;margin-top:40px">
              <el-button type="primary" @click="applying">提交申请</el-button>
              <el-button type="primary" style="margin-left:100px">取消</el-button>
     </div>
      <el-dialog
        title="提交开票申请成功"
        :visible.sync="dialogVisible"
        width="40%">
        <span style="text-align:center">您的开票申请已经提交，您选择的是{{shipping}}，届时可以登录云途志官网查看运单号。</span>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:center;margin-bottom:20px">
            <el-button  @click="$router.push({name:'workbench-submit'}) ">我的提交</el-button>
            <el-button type="primary" @click="seeName"  style="margin-left:15%">查看详情</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
    </el-card>
  </div>
</template>

<script>
import { applyList ,add,addressInfo,apply,} from "@/api/ticketing.js";
export default {
  data() {
    return {
      shipping:'',
      dataList: [],
      dialogVisible:false,
      // 分页
      pageSize: 3,
      totalPage: 0,
      totalCount: 0,
      currPage: 1,
      radio:'',
      desc:'',//备注
      ruleForm:{
          invoice:'',
          express:'',
      },
      rules:{
          
      },
      ids:[],
      dataForm:{},//开票设置-开票信息
      addressArr:[],//地址
      addressArrobj:{},
      activeIndex:0,
      receiverInfoId:"",
      idx:'',
    };
  },
  mounted(){
    if ( this.$store.state.user.idarr) {
      this.$store.state.user.idarr.map(item=>{
          this.ids.push(item.id)
        })
    }
    this.getDataList()
    this.info() 
  },
  methods: {
      getDataList(){
        this.$forceUpdate()
        let params ={
          "ids": this.ids,
          "current": this.currPage,
          "size": this.pageSize,
        }
       
       applyList(params).then((data) => {
          if(data.code == 200) {
            this.dataList = data.data.records
            this.totalCount = data.data.total
            
          }
        })
      },
      //开票信息
   info(){
     add().then(data=>{
       if (data&&data.code==200) {
          this.dataForm=data.data
          // console.log(this.dataForm);
          this.radio=data.data.sendType.toString()
       }
     })
      this.ress()
   },
    ress(){
      addressInfo().then(data=>{
       if (data&&data.code==200) {
          this.addressArr=data.data
           this.receiverInfoId=this.addressArr[0].id
       }
     })
   },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.currPage = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.currPage = val;
      this.getDataList();
    },
    inter(){
     this.activeIndex = this.$refs.carousel.activeIndex
     this.receiverInfoId=this.addressArr[this.activeIndex].id
     },
     
     //提交申请
     applying(){
       console.log(this.radio,'radio');
       if(this.radio == 502010) {
         this.shipping = '月寄'
       }
       if (this.radio == 502020) {
         this.shipping = '邮寄到付'
       }
       let valuedata={
         ids:this.ids,
         sendType:this.radio,
         remark:this.desc,
         receiverInfoId:this.receiverInfoId,
         basicInfoId:this.dataForm.id,//开票基本信息
         idCnt:this.totalCount
       }
       console.log(this.ids,"ids11");
        apply(valuedata).then(data=>{
          console.log(data.data.id,'data.id');
       if (data&&data.code==200) {
         this.dialogVisible=true
         this.idx = data.data.id
       }else {
         this.$message.error(data.msg)
       }
     })
     },
     //查看详情
      seeName(){
        console.log(1);
     console.log(this.idx,'id----');
        // workbench/details
        this.dialogVisible=false
        this.$router.push({ 
          name : 'workbench/details',
          query:{
            id:this.idx
            }
        })
      },
  },
  

  destroyed () { 
    this.$store.commit("user/updateIdarr","");
    console.log( this.$store.state.user.idarr,"ppppppppppppp");
   }
};
</script>

<style scoped lang="scss">
.title{
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    background: #ffff;
    margin-top:-20px;
    margin-left:-20px;
    width: 105%;
    text-indent: 20px;
}
.main{
    margin-top: 10px;
}
.xunH {
  margin-top: 50px;
}
.heng {
  height: 1px;
  margin-top: 10px;
  background-color: rgb(223, 213, 213);
  overflow: hidden;
}
.ben {
  margin-top: 50px;
}
.text {
  margin-top: 20px;
  font-size: 14px;
}
.text1 {
  font-weight: 600;
  font-size: 14px;
}
div /deep/ .el-form-item {
    margin-bottom: 0;
}
.box {
    margin-top:30px;
    width: 420px;
    border: 1px solid #999;
}
.bob {
    margin-top:20px;
    // width: 420px;
    height: 200px;
    background-color: #f0f0f0;
}
.demo-ruleForm {
    margin-top: 20px;
}
.left1{
  margin-left:160px
}
  .box-card {
    width: 480px;
    display: inline-block;
    margin-left: 20px;
  }
   .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
  .el-carousel__item.el-carousel__item--card.is-in-stage {
  text-align: center;
 }
 /deep/.el-form-item__content{
   text-align: left;
 }
/*修改高度*/
 /deep/.el-carousel__container {
  height: 300px;
 }
 .van-collapse-item__content {
  padding: 14px 0;
 }
  /*左右箭头的样式*/
 button.el-carousel__arrow.el-carousel__arrow--left, button.el-carousel__arrow.el-carousel__arrow--right {
  font-size: 12px;
  height: 20px;
  width: 20px;
  background: #9e99b9;
 }
    .boxtop {
  width: 100%;
  height: 340px;
  margin-top:30px;
  // margin-left:200px
}
.active{
  color: #fff !important;
  background: #9e99b9;
}
/deep/.active .el-form-item__label{
  color: #fff !important;
}
</style>